.App {
  text-align: center;

}

.App-logo {
  /* height: 100%; */
    
  /* Background image settings */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the background image */
  
  /* Reduced opacity */
  opacity: 0.7; /* Change this value as needed */
  
}

#hero {
  width: 100%;
  height: 100vh;
  background: url("/public/img/hero.jpg");
  background-size: cover;
  background-position: 25% 75%;
  background-repeat: no-repeat;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(46, 21, 21, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
